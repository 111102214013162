<template>
  <div class="login">
    <div class="login__logo">
      <img
        src="/img/af-logo.svg"
        alt="">
    </div>
    <call-input
      v-model="$v.dataForm.email.$model"
      :valide="$v.dataForm.email.$error"
      type-input="text"
      float-name-label="Login" />
    <call-input
      v-model="$v.dataForm.password.$model"
      :valide="$v.dataForm.password.$error"
      show-password
      type="password"
      float-name-label="Password" />
    <call-button
      :icon="dataIconLogin"
      full
      type="success"
      @click="clickLogin()">
      Login
    </call-button>
    <call-error-version v-model="dataShowError" />
  </div>
</template>

<script>
import CallInput from '../components/common/CallInput';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import CallButton from '../components/common/CallButton';
import { LOGIN } from '../store/types/actions.types';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { Dialog } from 'vant';
import CallErrorVersion from '../components/common/modals/CallErrorVersion';

export default {
  name: 'PageLogin',
  components: { CallErrorVersion, CallButton, CallInput },
  mixins: [validationMixin],
  data () {
    return {
      dataIconLogin: faSignOutAlt,
      dataShowError: false,
      dataForm: {
        email: null,
        password: null,
        remember: true
      }
    };
  },
  validations: {
    dataForm: {
      email: {
        required
      },
      password: {
        required,
        minLength: minLength(4)
      }
    }
  },
  methods: {
    async clickLogin () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`auth/${LOGIN}`, { params: this.dataForm, context: this });
        if (this.$device.mobile) {
          this.$router.push({
            name: 'Projects'
          });
        } else {
          this.$router.push({
            name: 'Users'
          });
        }
      } catch (e) {
        if (e?.code === 100) {
          if (this.$device.mobile) {
            await Dialog.alert({
              title: 'Desktop Version',
              message: 'You should use desktop version',
              confirmButtonText: 'Ok'
            });
          } else {
            this.dataShowError = true;
          }
          return;
        }
        throw new Error('Ошибка авторизации');
      }
    }
  }
};
</script>
