<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Mobile Version"
    @close="onCloseModal">
    <div>
      You should use mobile version
    </div>
    <template v-slot:modal-footer>
      <call-button
        :icon="dataAddIcon"
        type="success"
        @click="onCloseModal()">
        Ok
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CallButton from '../CallButton';
import iconsModal from '../../../mixins/iconsModal';

export default {
  name: 'CallErrorVersion',
  components: {
    CallButton,
    BModal
  },
  mixins: [iconsModal],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataModal: this.value
    };
  },
  watch: {
    async value () {
      this.dataModal = this.value;
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    }
  }
};
</script>
